import './App.css';
/*
DIMENSIONS in: 
cost £
weight kg
volume U

Note that minus means that the component provides that 
*/

import React, {useState} from 'react';
import { clarity } from 'react-microsoft-clarity';

const components = [
  {
    "name": "Chassis",
    "options": [
      {
        "itemID": "Chassis-6U",
        "name": "6U",
        "cost": 6400,
        "weight": 850,
        "holdsVolume": 6,
        "powerConsumption": 0,
        "activeTime": 0,
      },
      {
        "itemID": "Chassis-8U",
        "name": "8U",
        "cost": 7900,
        "weight": 1300,
        "holdsVolume": 8,
        "powerConsumption": 0,
        "activeTime": 0,
      }
    ]
  },{
    "name": "Payload",
    "options": [
      {
        "itemID": "Payload-1",
        "name": "IM200",
        "cost": 27000,
        "weight": 59,
        "volume": 1,
        "powerConsumption": 3.5,
        "activeTime": 4,
      },
      {
        "itemID": "Payload-2",
        "name": "FFT2G",
        "cost": 15000,
        "weight": 750,
        "volume": 2,
        "powerConsumption": 25,
        "activeTime": 1,
      }
    ]
  },{
    "name": "Solar Panel",
    "options": [
      {
        "itemID": "SP-6U-S",
        "name": "Solar Panel 6U Static",
        "cost": 13000,
        "weight": 390,
        "powerGeneration": 9.2,
        "requiredChassis": "Chassis-6U"
      },{
        "itemID": "SP-6U-D",
        "name": "Solar Panel 6U Deployable",
        "cost": 40000,
        "weight": 757,
        "powerGeneration": 18.4,
        "requiredChassis": "Chassis-6U"
      },{
        "itemID": "SP-8U-S",
        "name": "Solar Panel 8U Static",
        "cost": 17000,
        "weight": 470,
        "powerGeneration": 24,
        "requiredChassis": "Chassis-8U"
      },{
        "itemID": "SP-8U-D",
        "name": "Solar Panel 8U Deployable",
        "cost": 45000,
        "weight": 900,
        "powerGeneration": 48,
        "requiredChassis": "Chassis-8U"
      }
    ]
  },{
    "name": "Onboard Computer",
    "options": [
      {
        "itemID": "OBC-1",
        "name": "OBC 1",
        "cost": 6000,
        "weight": 100,
        "speed": 500,
        "volume": 1,
        "powerConsumption": 1,
      },{
        "itemID": "OBC-2",
        "name": "OBC 2",
        "cost": 9500,
        "weight": 75,
        "speed": 750,
        "volume": 1,
        "powerConsumption": 2,
      },{
        "itemID": "OBC-3",
        "name": "OBC 3",
        "cost": 3500,
        "weight": 130,
        "speed": 200,
        "volume": 1,
        "powerConsumption": 0.8,
      }
    ]
  },{
    "name": "Transmitters/Transceivers",
    "options": [
      {
        "type": "X",
        "itemID": "X-Trans",
        "name": "X-Band Transmitter",
        "cost": 27000,
        "weight": 250,
        "volume": 1,
        "powerConsumption": 12,
      },{
        "type": "S",
        "itemID": "S-Transmitter",
        "name": "S-Band Transmitter",
        "cost": 12000,
        "weight": 250,
        "volume": 1,
        "powerConsumption": 12,
      },{
        "type": "S",
        "itemID": "S-Transceiver I",
        "name": "S-Band Transceiver I",
        "cost": 11000,
        "weight": 150,
        "volume": 0.5,
        "powerConsumption": 8.5,
      },{
        "type": "UHF",
        "itemID": "UHF-Transceiver II",
        "name": "UHF-Band Transceiver II",
        "cost": 5000,
        "weight": 100,
        "volume": 0.5,
        "powerConsumption": 8.5,
      }
    ]
  },{
    "name": "Antennas",
    // all antennas have an active time of 1hr and power consumption of 4W so no point writing that in
    "options": [
      {
        "itemID": "Antenna-1",
        "type": "X",
        "name": "X-Band 4x4 Patch Array",
        "cost": 17000,
        "weight": 53,
      },
      {
        "itemID": "Antenna-2",
        "type": "X",
        "name": "X-Band 2x2 Patch Array",
        "cost": 15000,
        "weight": 23,
      },
      {
        "itemID": "Antenna-3",
        "type": "X",
        "name": "X-Band Patch Antenna",
        "cost": 13000,
        "weight": 3,
      },{
        "itemID": "Antenna-4",
        "type": "UHF",
        "name": "UHF Antenna III",
        "cost": 15000,
        "weight": 85,
      },{
        "itemID": "Antenna-5",
        "type": "S",
        "name": "S-Band Antenna Commercial",
        "cost": 16000,
        "weight": 61,
      },{
        "itemID": "Antenna-6",
        "type": "S",
        "name": "S-Band Antenna ISM",
        "cost": 14000,
        "weight": 80,
      },{
        "itemID": "Antenna-7",
        "type": "S",
        "name": "S-Band Antenna Wideband",
        "cost": 15000,
        "weight": 115,
      }
    ]
  },{
    "name": "EPS",
    "options": [
      {
        "itemID": "EPS-1",
        "name": "EPS 1",
        "cost": 15250,
        "weight": 500,
        "volume": 1,
        "energyStored": 30,
      },{
        "itemID": "EPS-2",
        "name": "EPS 2",
        "cost": 18500,
        "weight": 550,
        "volume": 1,
        "energyStored": 34,
      },{
        "itemID": "EPS-3",
        "name": "EPS 3",
        "cost": 21000,
        "weight": 750,
        "volume": 1,
        "energyStored": 42,
      },{
        "itemID": "EPS-4",
        "name": "EPS 4",
        "cost": 27000,
        "weight": 1240,
        "volume": 1.5,
        "energyStored": 50,
      }
    ]
  },{
    "name": "Propulsion",
    "options": [
      {
        "itemID": "Prop-1",
        "name": "Bradford Ecaps 5N HPGP",
        "cost": 38000,
        "weight": 1400,
        "volume": 1,
        "maxWeight": 1000000,
      },{
        "itemID": "Prop-2",
        "name": "PM200 Bi Propellant Module 2U",
        "cost": 45000,
        "weight": 1800,
        "volume": 1.5,
        "maxWeight": 3500,
      },{
        "itemID": "Prop-3",
        "name": "Halo 12 HEET (Hall effect)",
        "cost": 80000,
        "weight": 2500,
        "volume": 2,
        "maxWeight": 2600,
      }
    ]
  }
]

function OptionMenu({component, selectedComponents, setSelectedComponents}) {
  return (
    <div className='optionGroup'>
      <h2>{component.name}</h2>
      {/* deselect button */}
      <button onClick={() => {
        setSelectedComponents({
          ...selectedComponents,
          [component.name]: null
        });
      }}>
        Deselect
      </button>
      {component.options.map((option, index) => {
        // if the option is already selected, make it have a green background
        var bgColour = "white";
        if (selectedComponents[component.name] === option.itemID) {
          bgColour = "lightgreen";
        }
        return (
          <div key={index} >
            <button style={{backgroundColor: bgColour}} onClick={() => {
              setSelectedComponents({
                ...selectedComponents,
                [component.name]: option.itemID
              });
            }}>
              {option.name}
            </button>
          </div>
        )
      })}
    </div>
  )
}

function RandomiseButton({selectedComponents, setSelectedComponents}) {
  // create a button that when clicked will randomise any unselected components
  return (
    <button onClick={() => {
      var newSelectedComponents = selectedComponents;
      Object.keys(selectedComponents).forEach(component => {
        if (selectedComponents[component] === null) {
          const randomIndex = Math.floor(Math.random() * components.find(comp => comp.name === component).options.length);
          newSelectedComponents[component] = components.find(comp => comp.name === component).options[randomIndex].itemID;
        }
      });
      // force a re-render
      setSelectedComponents({
        ...selectedComponents
      });
    }}>
      Randomise
    </button>
  )
}

function Calculations({selectedComponents}) {
  // if any of the components are not selected, return a message
  if (Object.values(selectedComponents).includes(null)) {
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        <h3>Not all components have been selected</h3>
      </div>
    )
  }
  // find the selected components
  const chassis = components[0].options.find(option => option.itemID === selectedComponents["Chassis"]);
  const payload = components[1].options.find(option => option.itemID === selectedComponents["Payload"]);
  const solarPanel = components[2].options.find(option => option.itemID === selectedComponents["Solar Panel"]);
  const onboardComputer = components[3].options.find(option => option.itemID === selectedComponents["Onboard Computer"]);
  const transmitters = components[4].options.find(option => option.itemID === selectedComponents["Transmitters/Transceivers"]);
  const antennas = components[5].options.find(option => option.itemID === selectedComponents["Antennas"]);
  const eps = components[6].options.find(option => option.itemID === selectedComponents["EPS"]);
  const propulsion = components[7].options.find(option => option.itemID === selectedComponents["Propulsion"]);
  // check if the chassis is compatible with the solar panel
  if (solarPanel && chassis && solarPanel.requiredChassis !== chassis.itemID) {
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        <h3>Chassis and Solar Panel are not compatible</h3>
      </div>
    )
  }
  // add up all the volumes 
  const volumes = payload.volume + onboardComputer.volume + transmitters.volume + eps.volume + propulsion.volume;
  if (volumes > chassis.holdsVolume) {
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        <h3>Volume of components exceeds volume of chassis</h3>
        <p>Volume of components = {volumes}</p>
        <p>Chassis volume = {chassis.holdsVolume}</p>
      </div>
    )
  }
  // check that antenna type is compatible with transmitter type
  if (antennas.type !== transmitters.type) {
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        <h3>Antenna and Transmitter are not compatible</h3>
      </div>
    )
  }
  // calculate whether all the electrical stuff matches up
  const maxActiveTime = payload.activeTime
  const totalPowerConsumption = payload.powerConsumption * payload.activeTime + onboardComputer.powerConsumption * maxActiveTime + transmitters.powerConsumption * 1 /* match antennas */ + /* for antennas */ 4;
  // calculate if UPS will be able to power everything
  const totalEnergyStored = eps.energyStored;
  if (totalPowerConsumption > totalEnergyStored) {
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        <h3>UPS cannot power all components</h3>
        <p>Total Power Consumption = {totalPowerConsumption}</p>
        <p>Total Energy Stored = {totalEnergyStored}</p>
      </div>
    )
  }
  // add up all the weights of all components
  const weightsWithoutPropulsion = chassis.weight + payload.weight + solarPanel.weight + onboardComputer.weight + transmitters.weight + antennas.weight + eps.weight;
  // get propultion max weight
  const propulsionMaxWeight = propulsion.maxWeight;
  // check if URL parameter is set to disable weight check
  const urlParams = new URLSearchParams(window.location.search);
  const weightCheck = urlParams.get('weightcheck');
  if (weightCheck !== "false") {
    if (weightsWithoutPropulsion > propulsionMaxWeight) {
      return (
        <div className='calculationContainer'>
          <h2>Calculations</h2>
          <h3>Weight of components exceeds max weight of propulsion</h3>
          <p>Weight of components (without propulsion) = {weightsWithoutPropulsion}</p>
          <p>Note this may be incorrect, all other validation has passed. To disable this check, add /?weightcheck=false to the URL (note reloading the page will delete current input) before the # if there is one</p>
          <p>Propulsion max weight = {propulsionMaxWeight}</p>
        </div>
      )
    }
  }
  // calculate how long it will take for the solar panel to charge the UPS
  const timeToCharge = totalEnergyStored / solarPanel.powerGeneration;
  console.log("timeToCharge", timeToCharge)
  console.log("totalEnergyStored", totalEnergyStored)
  console.log("solarPanel.powerGeneration", solarPanel.powerGeneration)
  // calculate the total cost, weight and volume
  const totalWeight = chassis.weight + payload.weight + solarPanel.weight + onboardComputer.weight + transmitters.weight + antennas.weight + eps.weight + propulsion.weight;
  const totalCost = chassis.cost + payload.cost + solarPanel.cost + onboardComputer.cost + transmitters.cost + antennas.cost + eps.cost + propulsion.cost + (70000 * totalWeight / 1000)
  const budget = 525000;
  const differenceFromBUdget = totalCost - budget;
  if(differenceFromBUdget > 0){
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        {/* if weight check is disabled show a warning */}
        {weightCheck === "false" && <h1 className='warn'>WARNING: WEIGHT CHECK DISABLED</h1>}
        <h3>Cost exceeds budget by {differenceFromBUdget}</h3>
        <h3>Cost: £{totalCost}</h3>
        <h3>Weight: {totalWeight}g</h3>
        <h3>Volume: {volumes}U</h3>
        <h3>Power Consumption: {totalPowerConsumption}Wh</h3>
        <h3>Energy Stored: {totalEnergyStored}Wh</h3>
      </div>
    )
  }else{
    return (
      <div className='calculationContainer'>
        <h2>Calculations</h2>
        {weightCheck === "false" && <h1 className='warn'>WARNING: WEIGHT CHECK DISABLED</h1>}
        <p>Remember you are required to use the lowest weight capacity propulsion system and this isn't checked as of yet.</p>
        <h3>Cost: £{totalCost}</h3>
        <h3>Weight: {totalWeight}g</h3>
        <h3>Volume: {volumes}U</h3>
        <h3>Power Consumption: {totalPowerConsumption}Wh</h3>
        <h3>Energy Stored: {totalEnergyStored}Wh</h3>
        <h3>Time to charge: {timeToCharge}hr</h3>
        <h2>UNDER BUDGET 😀😀😀😀</h2>
        <h1 className='warn'>CHECK CALCULATIONS BY YOURSELF: I COULD HAVE MADE A PROGRAMMING MISTAKE AND NOT ALL CASES ARE CHECKED (e.g. computer)</h1>
      </div>
    )
  }
}

function ShareButton({selectedComponents}) {
  // make each of the selected components into url parameters
  const urlParams = encodeURIComponent(JSON.stringify(selectedComponents));
  // create a URL with the parameters
  const url = "https://thales-wex-cubesat.pages.dev/#" + urlParams;
  return (
    <div>
      <h2>Share your selections</h2>
      <input type="text" value={url} readOnly/>
      <button id="copyButton" onClick={() => {
        navigator.clipboard.writeText(url);
        // update the button text to show that the URL has been copied
        document.getElementById("copyButton").innerText = "Copied!";
      }
      }>Copy</button>
    </div>
  )
}

function App() {
  clarity.init('lu12nicg2k');
  clarity.consent();
  // if there is a URL hash, set the selected components to the hash
  var initialSelectedComponents = {}
  if (window.location.hash) {
    initialSelectedComponents = JSON.parse(decodeURIComponent(window.location.hash.slice(1)));
  }else{
    initialSelectedComponents = {
      "Chassis": null,
      "Payload": null,
      "Solar Panel": null,
      "Onboard Computer": null,
      "Transmitters/Transceivers": null,
      "Antennas": null,
      "EPS": null,
      "Propulsion": null
    };
  }
  // create state for the currently selected components
  const [selectedComponents, setSelectedComponents] = useState(initialSelectedComponents);
  return (
    <div className="App">
      <h1>CubeSat</h1>
      <h2>Choose your components</h2>
      {components.map((component, index) => {
        return  <OptionMenu key={index} component={component} selectedComponents={selectedComponents} setSelectedComponents={setSelectedComponents}/>
      }
      )}
      <Calculations selectedComponents={selectedComponents}/>
      <ShareButton selectedComponents={selectedComponents}/>
      <RandomiseButton selectedComponents={selectedComponents} setSelectedComponents={setSelectedComponents}/>
      <p>Site by Isaac, not for the use of anyone outside of Team Mars Bars</p>
      <p>MS Clarity is in use</p>
    </div>
  );
}

export default App;
